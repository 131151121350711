/* eslint-disable no-undef */
/**
 * Author: Martin Kululanga
 * Github: https://github.com/m2kdevelopments
*/

import axios from "axios";
import Cookies from 'js-cookie';
const instance = axios.create();
const authUrl = process.env.REACT_APP_BACKEND; //Get Urls for APIs


export const GetAPI = (route: string, auth = true) => Get(`${authUrl}${route}`, auth);
export const PostAPI = (route: string, body = {}, auth = true) => Post(`${authUrl}${route}`, body, auth);
export const PatchAPI = (route: string, body = {}, auth = true) => Patch(`${authUrl}${route}`, body, auth);
export const DeleteAPI = (route: string, auth = true) => Delete(`${authUrl}${route}`, auth);

export function Get(url: string, auth = true) {
    return new Promise<any>(async resolve => {

        if (auth) {
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY + "");
            const headers = { 'Authorization': 'Bearer ' + token };
            try {
                const response = await instance.get(url, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(null);
            }

        } else {
            try {
                const response = await instance.get(url);
                resolve(response.data);
            } catch (e) {
                console.log({ error: e });
                resolve(null);
            }
        }
    });
}

export function Post(url: string, body = {}, auth = true) {
    return new Promise<any>(async resolve => {

        if (auth) {
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY + "");

            const headers = { 'Authorization': 'Bearer ' + token };


            try {
                const response = await instance.post(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(null);
            }


        } else {
            try {
                const response = await instance.post(url, body);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(null);
            }
        }
    });
}

export function Patch(url: string, body = {}, auth = true) {
    return new Promise<any>(async resolve => {

        if (auth) {
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY + "");
            const ourAppId = process.env.REACT_APP_ID;

            const headers = { 'Authorization': 'Bearer ' + token, 'App': ourAppId };


            try {
                const response = await instance.patch(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(null);
            }


        } else {
            try {
                const response = await instance.patch(url, body);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(null);
            }

        }
    });
}

export function Delete(url: string, auth = true) {
    return new Promise<any>(async resolve => {

        if (auth) {
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY + "");
            //refresh user if necessary

            const ourAppId = process.env.REACT_APP_ID;
            const headers = { 'Authorization': 'Bearer ' + token, 'App': ourAppId };


            try {
                const response = await instance.delete(url, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(null);
            }


        } else {
            try {
                const response = await instance.delete(url);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(null);
            }

        }
    });
}

export function getAccessToken() {
    return new Promise(resolve => resolve(Cookies.get(process.env?.REACT_APP_COOKIE_KEY?.toString() || "auth360")));
}
