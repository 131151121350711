import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

//import main css
import './index.css';
import App from './App';
 
//environment variables - https://www.npmjs.com/package/dotenv
require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);