import React, { useEffect, useState, useCallback, useContext } from 'react'
import { Alert, Button, Container, Form, FormControl, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { AnimationLoading } from '../components/Lottie'
import Navigation from '../components/Navigation';
import * as API from "../utils/api";
import swal from 'sweetalert';
import { DataGrid } from '@mui/x-data-grid';
import { ContextUser } from '../App';
import { TextField } from '@material-ui/core';
import { FaPlus, FaChrome } from "react-icons/fa";

interface Props { }

const PageApps: React.FC<Props> = () => {

    const history = useHistory();
    const [apps, setApps] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [creatingApp, setCreatingApp] = useState(false);
    const [search, setSearch] = useState("");
    const user = useContext(ContextUser);
    const [popup, setPopup] = useState(false);


    const columns = [
        { field: '_id', headerName: 'ID', align: 'center', width: 150 },
        {
            field: 'image',
            headerName: 'Img',
            align: 'center',
            width: 30,
            renderCell: (params: any) => <img width={30} style={{ borderRadius: "50%" }} alt="profile" src={params.value} />,
        },
        { field: 'name', headerName: 'Name', align: 'center', width: 150 },
        { field: 'github', headerName: 'Github', align: 'center', width: 100, renderCell: (params: any) => <a href={params.value}>{params.value}</a> },
        { field: 'created_by', headerName: 'Created By', align: 'center', width: 150, renderCell: (params: any) => <a href={params.value.link}>{params.value.name}</a> },
        { field: 'in_development', headerName: 'Deployed', align: 'center', width: 150, renderCell: (params: any) => !params.value ? "YES" : "NO" },
        {
            field: 'actions',
            headerName: 'ACTIONS',
            align: 'center',
            width: 360,
            renderCell: (params: any) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Button variant="outline-primary" onClick={() => onToggleDevelopment(params.value)} className="hover round dropShadow">{params.value.in_development ? "GO LIVE" : "GO DEV"}</Button>
                    <Button variant="outline-primary" onClick={() => onMore(params.value)} className="hover round dropShadow">MORE</Button>
                    <Button variant="outline-primary" onClick={() => history.push(`/upload/${params.value._id}`)} className="hover round dropShadow">UPLOAD</Button>
                    <Button variant="primary" onClick={() => onDelete(params.value)} className="hover round dropShadow">DELETE</Button>
                </div>
            ),
        },
    ] as any



    useEffect(() => {
        const run = async () => {
            try {
                const apps = await API.GetAPI('/developers/apps');
                setApps(apps.map((app: any) => {
                    return {
                        ...app,
                        id: app._id,
                        actions: app,
                    }
                }));
            } catch (e: any) {
                console.log(e.message);
            } finally {
                setLoaded(true);
            }
        }
        run();
    }, []);

    const onToggleDevelopment = async (app: any) => {
        const result = await swal({
            title: app.in_development ? `Go Live` : `Go in Dev Mode`,
            text: app.in_development ? `Are you sure you want ${app.name} in Live Mode` : `Are you sure you want ${app.name} in Dev Mode`,
            icon: "info",
            buttons: ['CANCEL', app.in_development ? `GO LIVE` : `IN DEV MODE`]
        })

        if (!result) return;

        const updatedApp = { ...app, app_id: app._id, in_development: !app.in_development };
        try {
            setLoaded(false);
            await API.PatchAPI('/developers/update/app', updatedApp);
            const apps = await API.GetAPI('/developers/apps');
            setApps(apps.map((app: any) => {
                return {
                    ...app,
                    id: app._id,
                    actions: app,
                }
            }));
        } catch (err: any) {
            console.log(err.message);
        } finally {
            setLoaded(true);
        }
    }

    const onSubmitApp = async (e: any) => {
        e.preventDefault();
        const app = {
            name: e.target.name.value,
            description: e.target.description.value,
            github: e.target.github.value
        }

        if (app.github.match(/github.com/)) {
            setCreatingApp(true);
            try {
                const response = await API.PostAPI('/developers/create/app', app);
                if (response.result) {
                    const apps = await API.GetAPI('/developers/apps');
                    setApps(apps);
                    e.target.name.value = "";
                    e.target.description.value = "";
                    e.target.github.value = "";
                    swal(`App ${app.name} Created`)
                } else {
                    console.log('Failure');
                    swal("Failed");
                }
            } catch (e: any) {
                console.log(e.message);
            } finally {
                setCreatingApp(false);
            }
        } else {
            console.log("Repo is not from github");
            swal('Repo is not from github')
        }
    }

    const onMore = (app: any) => history.push(`/app/${app._id}`);

    const onDelete = async (app: any) => {

    }

    const filter = useCallback((app: any) => {
        if (search.replace(/\s/gmi, '') === "") return true;
        return app.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }, [search]);

    if (user === null || user === undefined) {
        history.push('/');
    }

    if (loaded === false) {
        return <AnimationLoading title="Loading Apps" />
    }

    return (
        <div>
            <Navigation />


            <Container fluid style={{ paddingTop: 20 }}>
                {
                    apps.length === 0 ? null :
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ color: "grey" }}>APPS</h1>
                                <TextField style={{ width: "50%", margin: 20 }} label="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                <Button variant="primary" onClick={() => setPopup(true)} className="round hover dropShadow">
                                    <FaPlus size={20} /> <FaChrome size={20} />Add App
                                </Button>
                            </div>

                            <div style={{ height: '70vh', width: '100%' }}>
                                <DataGrid
                                    getRowId={(row: any) => row._id}
                                    rows={apps.filter(filter)}
                                    columns={columns}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                />
                            </div>
                        </>
                }

            </Container>

            <Modal show={popup} onHide={() => setPopup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="modal-title">CREATE BOS APP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        creatingApp ? <AnimationLoading title="Creating App" /> :

                            <Container className="centralise" style={{ paddingTop: 40 }}>
                                <Alert variant="light" className="dropShadow">
                                    <Alert.Heading>
                                        <h3>{apps.length === 0 ? "Don't Have Any Apps yet?" : "Create A New App Here"}</h3>
                                        <h4><i>For BOS</i></h4>
                                    </Alert.Heading>
                                    <Container>
                                        <Form onSubmit={onSubmitApp}>
                                            <h5 style={{ float: "left" }}>App Name</h5>
                                            <FormControl required name="name" type="name" placeholder="App Name" />
                                            <br />
                                            <h5 style={{ float: "left" }}>App Description</h5>
                                            <FormControl required name="description" type="name" placeholder="App Description" />
                                            <br />
                                            <h5 style={{ float: "left" }}>Github Repo Url</h5>
                                            <FormControl required name="github" type="url" placeholder="https://github.com/Business-On-Steroids/Example360" />
                                            <br /><br />
                                            <Button id="form" type="submit" variant="dark" className="round dropShadow hover">Create App</Button>
                                        </Form>
                                    </Container>
                                </Alert>

                            </Container>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setPopup(false)} >
                        CANCEL
                    </Button>
                    <Button disabled={creatingApp} variant="primary" onClick={() => document.getElementById('form')?.click()}>
                        SEND
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default PageApps
