import Cookies from 'js-cookie';
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ContextSetUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";

function PageOAuth() {

    const {accessToken} = useParams<any>();
    const history = useHistory();
    const setUser = useContext(ContextSetUser);

    useEffect(() => {
       async function run(){
            if(accessToken){
                Cookies.set(process.env.REACT_APP_COOKIE_KEY+"", accessToken);
                const user = await API.GetAPI('/developers/me');
                setUser(user);
                history.push('/apps');
            }
       }

       run();
    }, [accessToken, setUser, history])

    return <AnimationLoading width={400} title="Logging In"/>
}

export default PageOAuth
