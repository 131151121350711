import React, { useState } from 'react';
import {BrowserRouter as Router, Switch, Redirect, Route} from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import ConnectionError from "./components/ConnectionError";
 
import PageLogin from "./pages/PageLogin";
import PageApps from "./pages/PageApps";
import PageDevelopers from './pages/PageDevelopers';
import PageApp from './pages/PageApp';
import PageOAuth from './pages/PageOAuth';
import PageUploadApp from './pages/PageUploadApp';
 
export const ContextUser = React.createContext(null as any);
export const ContextSetUser = React.createContext((user:any)=>{});
 
const App = () => {
  
    const [user, setUser] = useState(null as any);
 
    return (
          <Router>

                <ScrollToTop />
                <Switch>
                <ContextUser.Provider value={user}>
                    <ContextSetUser.Provider value={setUser}>
                            <Route exact path="/" component={()=><PageLogin />}  />
                            <Route       path="/oauth/:accessToken" component={()=><PageOAuth />} />
                            <Route exact path="/apps" component={()=><PageApps  />} />
                            <Route       path="/app/:appId" component={()=><PageApp  />} />
                            <Route       path="/upload/:appId" component={()=><PageUploadApp />} />
                            <Route exact path="/developers" component={()=><PageDevelopers />} />
                            <Route exact path="/404" component={()=><ConnectionError message="Check your internet connection and re-open the extension" tryAgain={false} callback={null} />} />
                            <Redirect to="/"/>
                    </ContextSetUser.Provider>   
                    </ContextUser.Provider>         
                </Switch>
            </Router>
  );
}

export default App;