


import { useHistory } from "react-router-dom";

// React Bootstrap Imports
import {   Alert, Button,Container, Form, FormControl} from 'react-bootstrap';
import { AnimationLoading } from '../components/Lottie';
import {  useContext, useEffect, useState } from 'react';

//My Libraries
import ConnectionError from "../components/ConnectionError";
import logo from "../images/logo.png";
import * as API from "../utils/api";
import Cookies from 'js-cookie';
import { ContextSetUser } from "../App";
import { FaMailBulk } from "react-icons/fa";
 
// https://react-bootstrap.github.io/
/* Using React Bootstrap */

const PageLogin = () => {

    //react hook use state - live updates
    const [loading, setLoading] = useState(false); //its true to wait for auto login
    const history = useHistory();
    const [error, setError] = useState(false);
    const [warning, setWarning] = useState("");
    const [loadingPassword, setLoadingPassword] = useState(false);
    const setUser = useContext(ContextSetUser);

    useEffect(() => {
        
        const run = async ()=>{
            
            try{
                const user = await API.GetAPI('/developers/me');
                if(user){
                    setUser(user);
                    history.push('/apps');
                }
            }catch(e:any ){
                console.log(e.message);
            }
        }
        run()
    }, [history, setUser]);

    const onForgotPassword = async () => {

        const email: string = (document.getElementById('email') as HTMLInputElement).value;
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        
        //check if email is empty
        if(email.trim() === ""){
            //alertify.warn("Please enter your email first");
            //setWarning("Please enter your email first");
            alert(`Please enter your email first`);
        }
        
        // if its is NOT an email
        else if(!emailPattern.test(email)){ 
            //alertify.warn(`${email} is not a valid email`);
            //setWarning(`${email} is not a valid email`);
            alert(`${email} is not a valid email`);
        }
        
        // if email is fine
        else{
            setLoadingPassword(true);

            //get email
            try{
                const response = await API.PostAPI(`/developers/forgotPassword`, {email:email}, false)
                setLoadingPassword(false);
                
                //if email was sent
                if(response){
                    //alertify.warn(`Please check your email <${email}> to get your new password`);
                    //setWarning(`Please check your email <${email}> to get your new password`);
                    alert(`Please check your email <${email}> to get your new password`)
                }
    
                
            }catch(error){
                //setWarning(`Sorry. Could not reset password for <${email}>`);
                alert(`Sorry. Could not reset password for <${email}>`)
                setLoadingPassword(false);
            }
        }
    };

    const onEmail = async (e:any) => {
        e.preventDefault();
        if(!loading){

            setLoading(true);

            try{
                const email = e.target.email.value;
                const password = e.target.password.value;
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
             
                //check if email is empty
                if(email.trim() === ""){
                    //alertify.warn(`Please enter your email first`);
                    alert("Please enter your email first");
                }
                
                // if its is NOT an email
                else if(!emailPattern.test(email)){ 
                    //alertify.warn(`${email} is not a valid email`);
                    alert(`${email} is not a valid email`);
                }

                else{
                    const {access_token} = await API.PostAPI('/developers/email/oauth', {email, password}, false)
                    if(access_token){
                        Cookies.set(process.env.REACT_APP_COOKIE_KEY+"", access_token);
                        const user = await API.GetAPI('/developers/me');
                        setUser(user);
                        history.push('/apps');
                    }
                    
                }

            }catch(e:any){
                console.log(e.message);
                alert(`Login failed`);
                setError(true);
            }finally{
                setLoading(false);
            }
        } 
    }

    // const onGithub = () => {
    //     const client_id = process.env.REACT_APP_GITHUB_APP_ID;
    //     const redirect_uri = `${process.env.REACT_APP_BACKEND}/developers/github/oauth`;
    //     const scope = "user"
    //     const state = "randomCodeToPreventHackers"
    //     const allow_signup = "true";
    //     const url = `https://github.com/login/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&allow_signup=${allow_signup}`
    //     window.location.href = url;        
    // }
 
    if(warning !== ""){
        return <Container className="centralise" fluid="sm" style={{paddingTop:50, paddingBottom:50, width:500}}>
            <h2>{warning}</h2>
            <br/>
            <Button variant="primary" className="hover round dropShadow" onClick={() => setWarning("")}>
                <strong>TRY AGAIN</strong>
            </Button>
        </Container>
    }
    
    if(error){
        return <ConnectionError message="Check your internet connection and re-open the extension" tryAgain={false} callback={null} />
    }

    if(loading){
        return <AnimationLoading title="Logging into Account"/>
    }

    if(loadingPassword){
        return <AnimationLoading title="Generating New Password"/>
    }

    return (
        <>
          
            <Container className="centralise" style={{paddingTop:60}}>
                <Alert variant="light" className="centralise round dropShadow" style={{paddingTop:50, paddingBottom:50, width:500}}>
                    <br/> <br/>   
                    {
                        loading ? <AnimationLoading title="Loading..." width={150} /> : 
                        <img src={logo} alt="Dashboard" style={{width:140}}/>
                    }
                    <h2>Login <strong>Developers Dashboard</strong></h2>
                    <br/>
                    <Form onSubmit={onEmail}>
                        <Form.Label style={{float:"left"}}><h5>Email</h5></Form.Label>
                        <FormControl disabled={loading} name="email" aria-label="email" id="email" required type="email" placeholder="Email" />
                        <br/>
                        <Form.Label style={{float:"left"}}><h5>Password</h5></Form.Label>
                        <FormControl disabled={loading} name="password" aria-label="password" required type="password" placeholder="Password" />
                        <br/>
                        <Button style={{width:"100%"}} disabled={loading} variant="dark" className="hover dropShadow" type="submit">
                            <FaMailBulk style={{marginRight:10}} size={30}/>
                            <strong>Login</strong>
                        </Button>
                    </Form>
                    <br/>
                    <Container className="centralise" fluid="sm">
                         
                        {
                            /**
                            <Button disabled={loading} variant="secondary" size="lg" className="hover dropShadow" onClick={onGithub}>
                                <img src="https://img.icons8.com/fluency/30/000000/github.png" alt="GITHUB"/>
                                <strong>LOGIN WITH GITHUB</strong>
                            </Button>
                             */
                        }
      
                        <Button  style={{width:"100%"}} variant="light"  className="hover dropShadow" disabled={loading} onClick={() => onForgotPassword()}>
                            <i>Forgot Password?</i>
                        </Button>
                    </Container>
                    <br/><br/>
                </Alert>
                
             
                
            </Container>
                        
        </>
    );
}

export default PageLogin;