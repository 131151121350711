


import React from 'react'
import { Button } from 'react-bootstrap'
import { AnimationError } from './Lottie'

interface Props {
    message:string, 
    tryAgain:boolean, 
    callback?: any
};


const ConnectionError: React.FC<Props> = ({message, tryAgain, callback}) => {

    const icon = "https://img.icons8.com/ios-filled/30/ffffff/restart--v1.png";

    return (
        <>
           <AnimationError title="Sorry Something Went Wrong" width={400}/>
            <div className="centralise">
                <h6>{message}</h6>
                <br/>
                {
                    tryAgain? 
                    <Button variant="danger" size="lg" className="dropShadow hover round" onClick={callback}>
                        <img src={icon} alt="Try Again"/>{" "}<strong>TRY AGAIN</strong>
                    </Button>
                    :null
                }
            </div> 
        </>
    )
}

export default ConnectionError
