import { useEffect, useState } from 'react'
import { Alert, Button, Container } from 'react-bootstrap'
import Navigation from '../components/Navigation'
import * as API from "../utils/api";
import swal from 'sweetalert';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { FaChrome } from 'react-icons/fa';
import { AnimationLoading } from '../components/Lottie';

function PageUploadApp() {

    const [loading, setLoading] = useState(false);
    const { appId } = useParams<any>();
    const [app, setApp] = useState(null as any);


    useEffect(() => {
        const run = async () => {
            try {
                setLoading(true);
                const apps = await API.GetAPI('/developers/apps');
                const app = apps.find((app: any) => app._id === appId);
                if (app) setApp(app);

            } catch (e: any) {
                console.log(e.message);
            } finally {
                setLoading(false);
            }
        }
        run();
    }, [appId])

    const onUpdateApp = async (e: any) => {

        const file = e.target.files[0];
        const mbs = 30;
        const maxSize = mbs * 1000 * 1000;


        if (file.size > maxSize) {
            swal(`Image is too large. Image should be less than ${mbs}MB`);
            return e.target.value = null;
        }


        if (!file.type.match(/zip/gmi)) {
            swal(`Invalid App`);
            return e.target.value = null;
        }

        try {
            setLoading(true);
            const token = await API.getAccessToken();
            const formdata = new FormData();
            formdata.append('app', file);
            formdata.append('name', file.filename);
            const domain = process.env.REACT_APP_BACKEND;
            const res = await axios({
                url: `${domain}/api/apps/${appId}`,
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                data: formdata
            })

            if (res.data.result) swal(res.data.message);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            e.target.files = null;
            e.target.value = null;
        }
    }


    if (!app || loading) {
        <div>
            <Navigation />
            <Container>
                <Alert variant="light" className="dropShadow">
                    <Alert.Heading>
                        <h1>BOS App Updater</h1>
                    </Alert.Heading>
                </Alert>
                <br />
                <AnimationLoading title="Loading App" width={400} />
            </Container>
        </div>
    }

    return (
        <div>
            <Navigation />
            <br /><br />
            <Container>
                <Alert variant="light" className="dropShadow">
                    <Alert.Heading>
                        <h1>BOS App Updater For
                            <img src={app?.image} width={40} alt="app"/>
                            {app?.name}
                        </h1>
                    </Alert.Heading>
                </Alert>
                <br />
                <input id="input" onChange={onUpdateApp} type="file" style={{ display: "none" }} accept=".zip,.rar,.7zip" />
                <Button variant="primary" className="round" onClick={() => document.getElementById('input')?.click()}>
                    <FaChrome size={30} />{" "}<strong>Upload Extension</strong>
                </Button>
            </Container>
        </div>
    )
}

export default PageUploadApp