


import  { useLottie } from "lottie-react";
import * as lottieLoading from '../res/lottie_loading.json';
import * as lottieNoConnection from '../res/lottie_noconnection.json';
import * as lottieUpgrade from "../res/lottie_upgrade_version.json";

interface LottieProps {
  lottie:any
}

const Lottie: React.FC<LottieProps> = ({lottie}) => {
  
  const options = {
    animationData: lottie.default,
    loop: true,
    autoplay: true,
  };
 
  const { View } = useLottie(options);
 
  return View;
}

interface Props {
  lottie:any,
  width?:number,
  height?:number,
  title?:string
}
const Animation: React.FC<Props> = ({lottie, width, height, title}) => {
  return <div className="centralise" style={{width:width, height:height}}>
          <Lottie lottie={lottie} />
          <hr/>
          <h4 style={{color:"ghostwhite"}}>{title}</h4>
        </div>
}

interface AnimProps {
  width?:number,
  title?:string
}

export const AnimationLoading: React.FC<AnimProps> = ({width, title}) => <Animation title={title} lottie={lottieLoading} width={width} />

export const AnimationError: React.FC<AnimProps> = ({width, title}) => <Animation title={title} lottie={lottieNoConnection} width={width}  />

export const AnimationUpgrade: React.FC<AnimProps> = ({width, title}) => <Animation title={title} lottie={lottieUpgrade} width={width} />