import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import icon from "../images/logo.png";
import { Link, useHistory} from "react-router-dom";
import React, { useContext } from "react";
import Cookies from 'js-cookie';
import { ContextSetUser, ContextUser } from "../App";

 
const Navigation = () => {

    const history = useHistory();
    const user = useContext(ContextUser);
    const setUser = useContext(ContextSetUser);
  
    const onLogout = () => {
        setUser(null);
        Cookies.set(process.env.REACT_APP_COOKIE_KEY+"", "");
        history.push('/');
    }
 
    return(
            <Navbar sticky="top" expand="lg" bg="dark" variant="light">
                <Navbar.Brand style={{margin:0, marginLeft:40}}>
                    <img src={icon} width="50" height="50" className="hover d-inline-block align-top" alt="Auth360" />
                </Navbar.Brand>
                <Nav.Link as={Link}  style={{ color: "ghostwhite" }}  to="/apps"><h6><strong>Developer Dashboard</strong></h6></Nav.Link>
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    <Nav.Link as={Link} className="hover" style={{ color: "ghostwhite" }}  to="/apps"><h6><strong>Apps</strong></h6></Nav.Link>
                    <Nav.Link as={Link} className="hover"  style={{ color: "ghostwhite" }}  to="/developers"><h6><strong>Developers</strong></h6></Nav.Link>
                    <Nav.Link as={Link} className="hover"  style={{ color: "#EE331A" }} onClick={onLogout} to="#"><h6><strong>Logout ({user?.name.split(" ")[0]})</strong></h6></Nav.Link>
                </Nav>
            </Navbar>
    );
}

export default Navigation;

