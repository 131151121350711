import React, { useContext, useEffect, useState } from 'react';
import { Alert, Container, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ContextUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import Navigation from '../components/Navigation';
import * as API from "../utils/api";

interface Props{
     
}

const PageDevelopers : React.FC<Props> = ( ) => {

    const user = useContext(ContextUser);
    const [developers, setDevelopers] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const run = async () => {
            const developers = await API.GetAPI('/developers/all');
            setDevelopers(developers);
        }
        run();
    }, []);

    if(developers.length === 0){
        return <AnimationLoading title="Loading Developers"/>
    }

    if(user === null || user === undefined){
        history.push('/login')
    }


    return (
        <>
            <Navigation />
            <Container  style={{padding:50}} >
                <Alert variant="light" className="dropShadow">
                    <Alert.Heading>
                        <h1>BOS Developers</h1>
                    </Alert.Heading>
                </Alert>
                <br/>
                <Table variant="light" className="dropShadow">
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>GITHUB</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            developers.map((developer:any, index:number) => 
                                <tr key={index}>
                                    <td>
                                        <img src={developer.image} alt={developer.name} style={{width:40}}/>
                                        {developer.name}
                                    </td>
                                    <td>{developer.email}</td>
                                    <td><a href={developer.link}>{developer.link}</a></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Container>
       </>
    );
}

export default PageDevelopers