import React, { useEffect, useState, useContext } from 'react';
import {
  Alert,
  Button,
  Container,
  Form,
  FormControl,
  Table,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { AnimationLoading } from '../components/Lottie';
import Navigation from '../components/Navigation';
import * as API from '../utils/api';
import Switch from 'react-switch';
import twilio from '../images/twilio.png';
import swal from 'sweetalert';
import { TextField } from '@mui/material';
import { ContextUser } from '../App';
import { FaQuestion } from 'react-icons/fa';

interface Props {
   
}

interface CopyButtonProps {
  value: string;
}

const featureValueTypes = ['Number', 'Boolean', 'Date'];

const PageApp: React.FC<Props> = ( ) => {
  const history = useHistory();
  const [app, setApp] = useState(null as any);
  const user = useContext(ContextUser);
  const [loading, setLoading] = useState(false);
  const [loadingFeature, setLoadingFeature] = useState(false);
  const { appId } = useParams<any>();
  const [development, setDevelopment] = useState(true);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [features, setFeatures] = useState([] as Array<any>);
  const [selectedFeature, setSelectedFeature] = useState('-1');

  const FirebaseIcon = () => (
    <img
      src="https://img.icons8.com/color/20/000000/google-firebase-console.png"
      alt="firebase"
    />
  );
  const TwilioIcon = () => (
    <img src={twilio} style={{ width: 20 }} alt="twilio" />
  );
  const GithubIcon = () => (
    <img
      src="https://img.icons8.com/fluency/20/000000/github.png"
      alt="github"
    />
  );
  const UninstallIcon = () => (
    <img
      src="https://img.icons8.com/fluency/20/000000/uninstalling-updates.png"
      alt="uninstall"
    />
  );
  const ChromeIcon = () => (
    <img
      src="https://img.icons8.com/fluency/20/000000/chrome.png"
      alt="chrome"
    />
  );
  const BackendIcon = () => (
    <img
      src="https://img.icons8.com/nolan/20/backend-development.png"
      alt="backend"
    />
  );
  const AppStoreIcon = () => (
    <img
      src="https://img.icons8.com/fluency/20/000000/apple-app-store.png"
      alt="backend"
    />
  );
  const GooglePlayStoreIcon = () => (
    <img
      src="https://img.icons8.com/fluency/20/000000/google-play.png"
      alt="backend"
    />
  );

  useEffect(() => {
    const run = async () => {
      try {
        const res = await API.GetAPI(`/developers/app/${appId}`);
        if (res) {
          setApp(res.app);
          setDevelopment(res.app.in_development);
          setFeatures(res.features);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    run();
  }, [appId]);

  const onSubmitApp = async (e: any) => {
    e.preventDefault();

    const updatedApp = {
      app_id: app._id,
      image: e.target.image.value,
      name: e.target.name.value,
      install_title: e.target.install_title.value,
      install_message: e.target.install_message.value,
      description: e.target.description.value,
      github: e.target.github.value,
      in_development: development,
      version: parseFloat(e.target.version.value),
      update_url: e.target.updateUrl.value,
      uninstall_url: e.target.uninstallUrl.value,
      firebase_sender: e.target.firebase_sender.value,
      firebase_cloud_messaging_key: e.target.firebase_cloud_messaging_key.value,
      twilio_key: "",
      chrome_webstore_url: e.target.chrome_webstore_url.value,
      android_webstore_url: e.target.android_webstore_url.value,
      ios_webstore_url: e.target.ios_webstore_url.value,
      base_url: e.target.base_url.value,
      thrivecart_product: e.target.thrivecart_product.value,
      checkout_page: e.target.checkout_page.value,
      share_link: e.target.share_link.value,
      share_text: e.target.share_text.value,
      help_url: e.target.help_url.value,
    };

    if (updatedApp.github.match(/github.com/)) {
      setLoading(true);

      try {
        const response = await API.PatchAPI('/developers/update/app',updatedApp);
        if (response.result) {
          
          console.log('Success');
          const res = await API.GetAPI(`/developers/app/${appId}`);
          const app = res.app;
          setApp(app);
          setDevelopment(app.in_development);
          swal(`${app.name} Updated!`);
        } else {
          console.log('Failure');
          swal('Failed');
        }
      } catch (err: any) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.log('Repo is not from github');
      swal('Repo is not from Github');
    }
  };

  const copyToClipBoard = (value: string) => {
    window.navigator.clipboard.writeText(value).then(() => {
      console.log('Copied to clipboard');
      swal('Copied to clipboard');
    });
  };

  const CopyButton: React.FC<CopyButtonProps> = ({ value }) => {
    return (
      <Button
        variant="light"
        className="hover"
        onClick={() => copyToClipBoard(value)}
      >
        <img
          src="https://img.icons8.com/ios-glyphs/20/000000/copy.png"
          alt="copy"
        />
      </Button>
    );
  };

  const downloadJson = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(features.map((feature) => feature))
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'data.json';

    link.click();
  };

  const onUpdateWebpushKey = async () => {
    try {
      setLoading(true);
      const response = await API.PatchAPI(`/developers/app/${app._id}/webpush`);
      if (response.result) {
        console.log('Success');
        const app = await API.GetAPI(`/developers/app/${appId}`);
        setApp(app);
        setDevelopment(app.in_development);
        swal('Generated New Webpush Keys');
      } else {
        console.log('Failure');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };


  const onFeature = async (e: any) => {
    e.preventDefault();
    try {
      setLoadingFeature(true);
      let min = -1;
      let max = -1;
      if (selectedFeature === featureValueTypes[2]) {
        //Date
        min = new Date(e.target.min.value).getTime();
        max = new Date(e.target.max.value).getTime();
      } else if (selectedFeature === featureValueTypes[0]) {
        //Number
        min = e.target.min.value;
        max = e.target.max.value;
      }

      const feature = {
        app_id: appId,
        name: e.target.name.value,
        description: e.target.description.value,
        type: selectedFeature,
        max: max,
        min: min,
      };

      const res = await API.PostAPI('/api/features', feature);
      if (res.result) {
        const r = await API.GetAPI(`/developers/app/${appId}`);
        if (r) {
          setApp(r.app);
          setDevelopment(r.app.in_development);
          setFeatures(r.features);
        }
        swal('Features', res.message, 'success');
      } else {
        swal('Features', 'Something went wrong', 'fail');
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoadingFeature(false);
    }
  };


  const onEditFeature = (featureId: string) => {};


  const onDeleteFeature = async (featureId: string) => {
    try {
      setLoadingFeature(true);
      const res = await API.DeleteAPI(`/api/features/${featureId}`);
      if (res.result) {
        const r = await API.GetAPI(`/developers/app/${appId}`);
        if (r) {
          setApp(r.app);
          setDevelopment(r.app.in_development);
          setFeatures(r.features);
        }
        swal('Features', res.message, 'success');
      } else {
        swal('Features', 'Something went wrong', 'fail');
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoadingFeature(false);
    }
  };

  if (user === null || user === undefined) {
    history.push('/login');
  }

  if (loading || app === null || app === undefined) {
    return <AnimationLoading title="Loading Apps" />;
  }

  return (
    <>
      <Navigation />
      <Container className="centralise">
        <Alert variant="light" className="dropShadow">
          <img src={app.image} alt={app.name} style={{ width: 100 }} />
          <br />
          <Form onSubmit={onSubmitApp}>
            {/* <h6 style={{ float: 'left' }}>
              App ID:{' '}
              <Badge variant="light">
                {app.appKey.substring(0, MAX_CHARACTERS) + '...'}
              </Badge>
              
              <CopyButton value={app.appKey} />
            </h6> */}
            <TextField label="App ID" placeholder="APP Key" defaultValue={app.appKey} disabled sx={{width:"80%"}}/>
            <CopyButton value={app.appKey} />
            <br />
            <br />
            {/* <h6 style={{ float: 'left' }}>
              Web Push Public Key:{' '}
              <Badge variant="light">
                {app.webpush_public_key.substring(0, MAX_CHARACTERS) + '...'}
              </Badge>
              <CopyButton value={app.webpush_public_key} />
            </h6> */}
            <TextField label="Web Push Public Key" placeholder="Web Push Public Key" defaultValue={app.webpush_public_key} disabled sx={{width:"80%"}}/>
            <CopyButton value={app.webpush_public_key} />
            <br />
            <br />
            {/* <h6 style={{ float: 'left' }}>
              Web Push Private Key:{' '}
              <Badge variant="light">
                {showPrivateKey
                  ? app.webpush_private_key.substring(0, MAX_CHARACTERS) + '...'
                  : stars}
              </Badge>
              <CopyButton value={app.webpush_private_key} />
            </h6> */}
            <TextField label="Web Push Private Key" placeholder="Web Push Public Key" defaultValue={app.webpush_private_key} disabled sx={{width:"80%"}}/>
            <CopyButton value={app.webpush_private_key} />
            <hr />
            <br />
            <br />
            <br />
            <br />
            <Button
              variant="outline-dark"
              className="round hover dropShadow"
              onClick={() => setShowPrivateKey(!showPrivateKey)}
            >
              {showPrivateKey ? 'Hide' : 'Show'} Private Key
            </Button>{' '}
            <Button
              variant="outline-dark"
              className="round hover dropShadow"
              onClick={() => onUpdateWebpushKey()}
            >
              <img
                src="https://img.icons8.com/fluency/24/000000/key.png"
                alt="webpush"
              />
              Generate New Keys For Webpush
            </Button>
            <br />
            <h6>In Development</h6>
            <Switch onChange={setDevelopment} checked={development} />
            <br />
            <br />
            <h6 style={{ float: 'left' }}>App Image</h6>
            <FormControl
              defaultValue={app.image}
              name="image"
              type="url"
              placeholder="App Image"
            />
            <br />
            <h6 style={{ float: 'left' }}>App Name</h6>
            <FormControl
              required
              defaultValue={app.name}
              name="name"
              type="name"
              placeholder="App Name..."
            />
            <br />
            <h6 style={{ float: 'left' }}>App Description</h6>
            <FormControl
              required
              defaultValue={app.description}
              name="description"
              type="name"
              placeholder="App Description..."
            />
            <br />
            <h6 style={{ float: 'left' }}>App Version</h6>
            <FormControl
              required
              defaultValue={app.version}
              name="version"
              type="number"
              step=".001"
              placeholder="App Version e.g 1.0"
              min={1}
              max={1000}
            />
            <br />
            <h6 style={{ float: 'left' }}>App Install Title</h6>
            <FormControl
              defaultValue={app.install_title}
              name="install_title"
              type="title"
              placeholder="App Install Title..."
            />
            <br />
            <h6 style={{ float: 'left' }}>App Install Message</h6>
            <FormControl
              defaultValue={app.install_message}
              name="install_message"
              type="message"
              placeholder="App Install Message..."
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <ChromeIcon />
              Chrome Web Store Url
            </h6>
            <FormControl
              defaultValue={app.chrome_webstore_url}
              name="chrome_webstore_url"
              type="url"
              placeholder="Chrome Web Store Url"
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <GooglePlayStoreIcon />
              Google Play Url
            </h6>
            <FormControl
              defaultValue={app.android_webstore_url}
              name="android_webstore_url"
              type="url"
              placeholder="Google Play Url"
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <AppStoreIcon />
              App Store Url
            </h6>
            <FormControl
              defaultValue={app.ios_webstore_url}
              name="ios_webstore_url"
              type="url"
              placeholder="App Store Url"
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <BackendIcon />
              Base Url
            </h6>
            <FormControl
              defaultValue={app.base_url}
              name="base_url"
              type="url"
              placeholder="Base Url"
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <GithubIcon />
              Github Repo Url
            </h6>
            <FormControl
              required
              defaultValue={app.github}
              name="github"
              type="url"
              placeholder="https://github.com/Business-On-Steroids/Example360..."
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <UninstallIcon />
              Update Url
            </h6>
            <FormControl
              required
              defaultValue={app.update_url}
              name="updateUrl"
              type="url"
              placeholder="https://app.mysocial360.com/appName/update"
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <FaQuestion />
              Help Url
            </h6>
            <FormControl
              required
              defaultValue={app.help_url}
              name="help_url"
              type="url"
              placeholder="Usually Google Site"
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <UninstallIcon />
              Uninstall Url
            </h6>
            <FormControl
              required
              defaultValue={app.uninstall_url}
              name="uninstallUrl"
              type="url"
              placeholder="https://app.mysocial360.com/appName/uninstall"
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <FirebaseIcon />
              Firebase Sender ID
            </h6>
            <FormControl
              defaultValue={app.firebase_sender}
              name="firebase_sender"
              type="text"
              placeholder="Firebase Sender ID..."
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <FirebaseIcon />
              Firebase Server Key
            </h6>
            <FormControl
              defaultValue={app.firebase_cloud_messaging_key}
              name="firebase_cloud_messaging_key"
              type="text"
              placeholder="Firebase Server Key..."
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <TwilioIcon />
              Thrivecart Product
            </h6>
            <FormControl
              defaultValue={app.thrivecart_product}
              name="thrivecart_product"
              type="text"
              placeholder="Thrivecart Product..."
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <TwilioIcon />
              Checkout Page
            </h6>
            <FormControl
              defaultValue={app.checkout_page}
              name="checkout_page"
              type="text"
              placeholder="Checkout Page..."
            />
            <br />
            <h6 style={{ float: 'left' }}>
              <TwilioIcon />
              Share Link
            </h6>
            <FormControl
              defaultValue={app.share_link}
              name="share_link"
              type="text"
              placeholder="Share Link..."
            />
            <br />
            <h6 style={{ float: 'left' }}>Share Text</h6>
            <FormControl
              required
              maxLength={60}
              defaultValue={app.share_text}
              name="share_text"
              type="name"
              placeholder="Share Text..."
            />
            <br />
            <Button
              disabled={loading}
              type="submit"
              variant="dark"
              className="round dropShadow hover"
            >
              Update App
            </Button>
          </Form>
        </Alert>

        <Alert variant="light" className="dropShadow">
          <Alert.Heading>
            <h2>Features</h2>
            <hr />
          </Alert.Heading>
          <Form onSubmit={onFeature}>
            <Form.Label style={{ float: 'left' }}>Name</Form.Label>
            <FormControl
              required
              name="name"
              type="text"
              placeholder="Feature"
            />
            <br />
            <Form.Label style={{ float: 'left' }}>Description</Form.Label>
            <FormControl
              required
              name="description"
              type="text"
              placeholder="Description"
            />
            <br />
            <select
              value={selectedFeature}
              onChange={(e) => setSelectedFeature(e.target.value)}
            >
              <option disabled value="-1">
                Select Type Of Value
              </option>
              {featureValueTypes.map((f, i) => (
                <option key={'feature' + i} value={f}>
                  {f}
                </option>
              ))}
            </select>
            <br />

            {
              // Number
              selectedFeature === featureValueTypes[0] ? (
                <div>
                  <Form.Label style={{ float: 'left' }}>Min Value</Form.Label>
                  <FormControl
                    required
                    min={-1}
                    defaultValue={-1}
                    name="min"
                    type="number"
                    placeholder="Min Valu"
                  />
                  <br />
                  <Form.Label style={{ float: 'left' }}>Max Value</Form.Label>
                  <FormControl
                    required
                    min={-1}
                    defaultValue={-1}
                    name="max"
                    type="number"
                    placeholder="Max Value"
                  />
                </div>
              ) : null
            }
            {
              // Date
              selectedFeature === featureValueTypes[2] ? (
                <div>
                  <Form.Label style={{ float: 'left' }}>Min Date</Form.Label>
                  <FormControl
                    name="min"
                    required
                    type="date"
                    placeholder="Min Date"
                  />
                  <br />
                  <Form.Label style={{ float: 'left' }}>Max Date</Form.Label>
                  <FormControl
                    name="max"
                    required
                    type="date"
                    placeholder="Max Date"
                  />
                </div>
              ) : null
            }
            <br />
            <Button
              disabled={loadingFeature}
              variant="dark"
              type="submit"
              className="round hover dropShadow"
            >
              <strong>ADD FEATURE</strong>
            </Button>
          </Form>
        </Alert>
        <br />
        <Table variant="light" className="dropdShadow">
          <thead>
            <tr>
              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th>TYPE</th>
              <th>MIN</th>
              <th>MAX</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {features.map((f: any) => (
              <tr key={f._id}>
                <td>{f.name}</td>
                <td>{f.description}</td>
                <td>{f.type}</td>
                <td>{f.min}</td>
                <td>{f.max}</td>
                <td>
                  <Button
                    disabled={loadingFeature}
                    variant="outline-dark"
                    onClick={() => onEditFeature(f._id)}
                  >
                    <strong>EDIT</strong>
                  </Button>
                  <Button
                    disabled={loadingFeature}
                    variant="outline-danger"
                    onClick={() => onDeleteFeature(f._id)}
                  >
                    <strong>DEL</strong>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <br />
        <Button onClick={downloadJson}>Down Permission Json</Button>
        <br />
      </Container>
    </>
  );
};

export default PageApp;
